.hero {
  margin: 40px;
  padding: 15px 30px;
  border-radius: 15px;
  background: #FFF;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
  padding: 30px 0 50px 0;
}
.hero .Wrapup-screen {
  margin-bottom: 0px;
}
