.Popup-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.404);
  z-index: 5;
}

.Popup {
  position: fixed;

  width: 80%;
  max-height: 80%;
  left: 50%;
  top: 50%;

  transform: translateX(-50%) translateY(-50%);

  border: 1px solid var(--darkgrey);
  border-radius: 5px;

  padding: 50px;
  background: white;

  display: flex;
  flex-direction: column;
  overflow: hidden;
}


.Popup .Popup-content {
  overflow-y: auto;
  /* border: 1px solid  red; */

}



.Popup.fullscreen {
  width: auto;
  max-height: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: none;

  transform: none;

}

.Popup .Popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.Popup .Popup-content {
  height: 100%;
  overflow-y: auto;
}
