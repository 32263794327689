.Header {

  background: #FFF;
  padding: 10px 40px;
  color: var(--lightgreen);
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.212);

  display: flex;
  /* justify-content: center; */
  align-items: center;


  position: sticky;
  top: 0;
  width: 100%;
  z-index: 5;
  margin-bottom: 20px;
}
.Header .line1,
.Header .line2 {
  /* border: 1px solid blue; */
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "left center right";
  align-items: center;
}

.Header .app-title {
  flex-grow: 1;
  text-align: center;
  grid-area: center;
}

.Header .btn-right {
  grid-area: right;
  color: #000;
  /* align-self: flex-end;
  justify-self: end; */

  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.Header .btn-right .icon-btn{ margin-left: 10px;}

@media screen and (max-width: 700px) {
  .Header .line1{
    grid-template-columns: auto;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "top" "center";
  }

  .Header .line2 {
    grid-template-columns: auto;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas: "left" "center" "bottom";
  }

  .Header .btn-right { grid-area: center; }

  .Header .app-title {
    grid-area: top;
    font-size: 20px;
  }

  .Header .app-title img { width: 50px; margin-right: 10px; }

}