.CardsList {
    width: 80%;
    margin: 0 auto;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;

    padding-bottom: 40px;
    /* justify-items: center; */
  }


  @media (max-width: 1350px) {
    .CardsList { width: 90%; }

    .CardsList.multiple-cards {
      grid-template-columns: repeat(2, 1fr)!important;
    }
  }

@media (max-width: 800px) {
  .CardsList.multiple-cards { grid-template-columns:  1fr!important; }
}