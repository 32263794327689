.ReleaseHeader {
  padding-bottom: 30px;
  width: 100%;
}

.ReleaseHeader .help-button { justify-self: flex-end; }

.ReleaseHeader .countdown { display: flex; align-items: center; }
.ReleaseHeader .countdown svg{ margin-right: 10px; }
.ReleaseHeader h2 { white-space: nowrap; }

.ReleaseHeader .countdown {
  font-size: 1.5em;
  padding: 10px;
}
.ReleaseHeader .countdown.warning { color: var(--red); }

.ReleaseHeader .progress-ctnr {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.ReleaseHeader .progress-title {
  position: absolute;
  top: 100%;
  padding-top: 5px;
}
.ReleaseHeader .progress {
  border-radius: 999px;
  overflow: hidden;

  height: 25px;
  background: var(--grey);
  position: relative;
  width: 100%;
  color: var(--darkgrey);
}

.ReleaseHeader .progress .progress-bar {
  /* background: linear-gradient(to right, var(--grey), var(--darkgrey)); */
  background: var(--lightgreen);
  transition: transform 2s;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  border-radius: 999px;

  display: flex;
  justify-content: flex-end;
  align-items: center;
}


.ReleaseHeader .progress .progress-current { color: white; margin-right: 10px;}
.ReleaseHeader .progress .progress-total {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReleaseHeader .end-release {
  justify-self: flex-end;
}



@media screen and (max-width: 700px) {
  .ReleaseHeader h2{
    font-size: 1em;
    grid-area: center;
  }
  .ReleaseHeader .help-button {
    font-size: 1em;
    width: 25px;
    height: 25px;
    grid-area: center;
  }

  .ReleaseHeader .countdown {
    font-size: 1em;
    padding: 5px;
    grid-area: bottom;
  }
  .ReleaseHeader .end-release {
    grid-area: bottom;
  }

  /* .ReleaseHeader .app-title { grid-area: center; } */




}