.PickButton {
  display: flex;
  align-items: center;
}

.PickButton-text {
  padding: 0 10px;
  font-size: 1.3em;
  color: var(--grey4);
}
.PickButton .icon { margin-right: 10px; }

.TOO_COMPLEX .PickButton-text { color: var(--red);}
.PICKED_PREV .PickButton-text { color: var(--bcg-green);}