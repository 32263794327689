.Pdf {
  width: 100%;
  /* border: 1px solid  red; */
  height: 100%;
}

.Pdf .pdfCtnr::before,
.Pdf .pdfCtnr::after{
  content: "";
  flex-grow: 1;
}
.Pdf .pdfCtnr{
  /* border: 1px solid  red; */
  overflow: auto;
  display: flex;

  /* justify-content: center; */
}

.Pdf .pdf-controls{

  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
}

.Pdf  .pdf-controls .btn{ margin: 0 10px; }
