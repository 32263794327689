.CardDetail-fills {
  border: 1px solid var(--grey3);

  --angle: 0deg;
  background: conic-gradient(var(--grey3) var(--angle), transparent var(--angle));

  position: relative;

  width: 40px;
  height: 40px;

  border-radius: 50%;

  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;


}

.CardDetail-fills .CardDetail-value {
  position: relative;
  z-index: 2;
  color: var(--bcg-green);
  font-weight: bold;
  font-size: 20px;

  text-shadow: rgb(255, 255, 255) 3px 0px 0px
  ,rgb(255, 255, 255) 2.83487px 0.981584px 0px
  ,rgb(255, 255, 255) 2.35766px 1.85511px 0px
  ,rgb(255, 255, 255) 1.62091px 2.52441px 0px
  ,rgb(255, 255, 255) 0.705713px 2.91581px 0px
  ,rgb(255, 255, 255) -0.287171px 2.98622px 0px
  ,rgb(255, 255, 255) -1.24844px 2.72789px 0px
  ,rgb(255, 255, 255) -2.07227px 2.16926px 0px
  ,rgb(255, 255, 255) -2.66798px 1.37182px 0px
  ,rgb(255, 255, 255) -2.96998px 0.42336px 0px
  ,rgb(255, 255, 255) -2.94502px -0.571704px 0px
  ,rgb(255, 255, 255) -2.59586px -1.50383px 0px
  ,rgb(255, 255, 255) -1.96093px -2.27041px 0px
  ,rgb(255, 255, 255) -1.11013px -2.78704px 0px
  ,rgb(255, 255, 255) -0.137119px -2.99686px 0px
  ,rgb(255, 255, 255) 0.850987px -2.87677px 0px
  ,rgb(255, 255, 255) 1.74541px -2.43999px 0px
  ,rgb(255, 255, 255) 2.44769px -1.73459px 0px
  ,rgb(255, 255, 255) 2.88051px -0.838247px 0px;
}


.CardDetail {
  text-align: center;
}
.CardDetail .detail-name {
  text-align: center;
  font-size: 0.8em;
}


.CardDetail:hover .tip { opacity: 1; }
.CardDetail .tip {

    position: absolute;
    background: rgba(0, 0, 0, 0.75);
    color: #FFF;
    font-size: 0.8em;
    bottom: calc(100% + 10px);
    padding: 2px 5px;

    left: 50%;
    transform: translateX(-50%);

    opacity: 0;

    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

    pointer-events: none;
    transition: opacity 0.2s;
}

@media (max-width: 800px) {
  .CardsList.multiple-cards { grid-template-columns:  1fr!important; }
}