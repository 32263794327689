.Wrapup-screen { margin-bottom: 50px; }

.Wrapup {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.Wrapup .col1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
}



.Wrapup .period-values { padding: 0px 15px; margin-bottom: 10px;}
.Wrapup .period-value { display: flex; }
.Wrapup .period-value .period-value-name { flex-grow: 1; margin-right: 10px;}
.Wrapup .period-value .period-value-val { font-weight: bold; color: var(--lightgreen);}


.Wrapup .period-value.positive,
.Wrapup .period-value.negative {
  background: var(--red);
  padding: 10px 15px;
  margin: 0 -15px;
  color: #FFF;
  border-radius: 15px;
  margin-top: 10px;
}
.Wrapup .period-value.positive { background: var(--lightgreen); }

.Wrapup .period-value.positive .period-value-val,
.Wrapup .period-value.negative .period-value-val { color: #FFF;}


.Wrapup .discount small { display: block; }


.Wrapup .impact-value {
  position: relative;

  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: var(--lightgreen);

  margin-bottom: 10px;
  padding-bottom: 10px;

  letter-spacing: 0px;

  text-align: center;
  line-height: 140px;
  font-size: 70px;
  font-weight: bold;

  color: #FFF;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.Wrapup .impact-title {
  position: absolute;
  width: 100%;
  font-size: 13px;
  font-weight: bold;
  line-height: 1em;
  bottom: 25px;
  text-shadow: none;
  /* color: var(--grey4); */
}

.Wrapup .col2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Wrapup .col2 h2{
  margin: 0;
  padding: 0px 15px;
  margin-bottom: 10px;
  font-size: 40px;
  color: var(--lightgreen);
}



@media (max-width: 500px) {
  .Wrapup { flex-direction: column-reverse; padding: 20px;}
  .Wrapup .col1 { margin-top: 20px; margin-left: 0;}
}


.Wrapup .col3 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  align-content: center;
  gap: 20px;
}