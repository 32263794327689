.MeetingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: rgba(0, 0, 0, 0.7);

  background-image: linear-gradient(45deg, #000000 25%, rgba(25, 25, 25, 0.8) 25%, rgba(25, 25, 25, 0.8) 50%, #000000 50%, #000000 75%, rgba(25, 25, 25, 0.8) 75%, rgba(25, 25, 25, 0.8) 100%);
  background-size: 14.14px 14.14px;


  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 3;

}

.MeetingOverlay > div{
  color: #fff;
  font-size: 2em;
  padding: 20px;
}

@supports ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))) {
  .MeetingOverlay {
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(5px);

  }
}