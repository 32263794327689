.LoginScreen {

  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  height: 100%;
  background: #FFF;
}
.LoginScreen h2{
  color: var(--bcg-green);
  margin: 0;
  display: none;
}
.LoginScreen form {
  /* border: 1px solid  red; */
  /* padding: 20px; */
  text-align: center;
  margin-bottom: 250px;
}

.LoginScreen form label{
  display: block;
  margin-bottom: 5px;
}

.LoginScreen form input{

  font-size: 1em;
  padding: 5px 10px;
  border-radius: 99px;
  border: 1px solid var(--darkgrey);

}

.LoginScreen .join-button {
  margin: 0 auto;
}

.LoginScreen form input:focus{
  border-color: var(--lightgreen);
  outline: none;
}

.LoginScreen .error{
  color: var(--red);

  margin-top: 10px;

}


.LoginScreen .app-title{
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: 50px;

  margin-bottom: 50px;
  margin-top: 50px;
}

.LoginScreen .app-title img {
  margin: 0;
  margin-bottom: 10px;
  width: 300px;
}


.LoginScreen .version {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 0.8em;
  padding: 10px;

}


@media screen and (max-width: 500px) {
  .LoginScreen .app-title img { width: 200px; }
  .LoginScreen .app-title{ font-size: 30px; }
}