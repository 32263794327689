.Card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px auto;
  margin-bottom: 0;
  border-radius: 15px;
  padding: 40px;
  overflow: hidden;
  color: var(--grey5);
  background: #FFF;
  width: 100%;
  max-width: 500px;

  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);

  transition: background 0.3s;
}

.Card .card-category {
  margin: 0;
  margin-bottom: 10px;
  color: var(--grey4);
  font-weight: normal;
  font-size: 1.3em;
}

.Card .card-top .card-title { margin: 0; color: var(--bcg-green);}
.Card .card-top .card-title .card-id{
  width: 30px;
  height: 30px;
  background: var(--bcg-green);

  /* padding-right: 1px; */
  display: inline-block;
  text-align: center;
  line-height: 29px;


  /* display: inline-flex;
  justify-content: center;
  align-items: center; */

  font-size: 18px;


  border-radius: 50%;
  color: #FFF;
  font-weight: bold;
  margin-right: 10px;
}

.Card .card-desc {
  flex-grow: 1;
  font-size: 1.3em;
  white-space: pre-wrap;
}

.Card .card-value {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.Card .card-value .card-value-title {
  color: var(--lightgreen);
  margin-right: 10px;
  min-width: 90px;
}
.Card .card-value .card-value-bar-ctnr {
  position: relative;
  flex-grow: 1;
  height: 30px;
}


.Card .card-value .card-second-value-bar,
.Card .card-value .card-value-bar {
  position: absolute;
  background: var(--lightgreen);
  top: 0;
  bottom: 0;
  left: 0;
  border-radius: 99px;
  min-width: 30px;
}

.Card .card-value .card-second-value-bar {
  background: var(--red);
  border-radius: 0 99px 99px 0;
  background-size: 5.66px 5.66px;

  min-width: 15px;


}

.Card .card-value .card-second-value-value {
  position: absolute;
  color: #FFF;
  top: 50%;
  transform: translateY(-50%);
  padding-right: 5px;
}

.Card .card-value .card-value-value {
  position: absolute;
  color: #FFF;
  top: 50%;
  left: 7px;
  transform: translateY(-50%);
  z-index: 2;
}


.Card .card-combinations {
  /* border: 1px solid  red; */
  font-size: 0.8em;
  margin-bottom: 5px;
}
.Card .card-combinations .positive {
  color: var(--gold);
}
.Card .card-combinations .negative {
  color: var(--red);
}


.Card .card-details {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px;

  margin-top: 10px;
  /* border: 1px solid  red; */
}

@media (max-width: 1250px) {
  .Card .card-details {
    grid-template-columns: repeat(2, 1fr);
  }
}





.Card .card-pick-btn {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}


.Card.PICKED_NOW { background: var(--lightgreen); }
.Card.PICKED_NOW .card-category { color: #FFF; }
.Card.PICKED_NOW .card-top .card-title { color: #FFF; }
.Card.PICKED_NOW .card-top .card-title .card-id{ background: #FFF; color: var(--lightgreen);}
.Card.PICKED_NOW .card-desc{ color: #FFF; }
.Card.PICKED_NOW .card-value .card-value-title {color: #FFF;}
.Card.PICKED_NOW .card-value .card-value-bar { background: #FFF;}
.Card.PICKED_NOW .card-value .card-value-value { color: var(--lightgreen);}


.Card.TOO_COMPLEX { background: var(--grey2); }
.Card.TOO_COMPLEX .card-top .card-title { color: var(--grey4); }
.Card.TOO_COMPLEX .card-top .card-title .card-id{  background: var(--grey3); color: #FFF;}
.Card.TOO_COMPLEX .card-value .card-value-title {color: var(--red);}
.Card.TOO_COMPLEX .card-value .card-value-bar { background: var(--red);}
.Card.TOO_COMPLEX .card-value .card-value-value { color: #FFF;}

.Card.LOCKED { background: var(--grey2); }
.Card.LOCKED .card-top .card-title { color: var(--grey4); }
.Card.LOCKED .card-top .card-title .card-id{  background: var(--grey3); color: #FFF;}
.Card.LOCKED .card-desc {  color: var(--grey4);}
.Card.LOCKED .card-value .card-value-title {color: var(--grey4);}
.Card.LOCKED .card-value .card-value-bar { background: var(--grey3);}
.Card.LOCKED .card-value .card-value-value { color: #FFF;}

/* .Card.PICKED_PREV .card-top .card-title { color: var(--bcg-green); }
.Card.PICKED_PREV .card-top .card-title .card-id{  background: var(--grey3); color: #FFF;} */
.Card.PICKED_PREV { background: #cafde9; }
.Card.PICKED_PREV .card-category { color: var(--bcg-green); }
.Card.PICKED_PREV .card-desc {  color: var(--bcg-green);}
.Card.PICKED_PREV .card-value .card-value-title {color: var(--grey4);}
.Card.PICKED_PREV .card-value .card-value-bar { background: var(--grey3);}
.Card.PICKED_PREV .card-value .card-value-value { color: #FFF;}

.Card.negative { background: var(--lightred); }

.Card.negative .card-top .card-title { color: var(--red); }
.Card.negative .card-category { color: var(--red); }
.Card.negative .card-top .card-title .card-id{  background: var(--red); color: #FFF;}
.Card.negative .card-value-complexity .card-value-title{ color: var(--grey5); }
.Card.negative .card-value-complexity .card-value-bar{ background: var(--grey5); }


.Card.negative .card-value-impact .card-value-title{ color: var(--red); }
.Card.negative .card-value-impact .card-value-bar{ background: var(--darkred); }
.Card.negative .card-value-impact .card-second-value-bar{ background-image: linear-gradient(-45deg, var(--darkred) 25%, var(--red) 25%, var(--red) 50%, var(--darkred) 50%, var(--darkred) 75%, var(--red) 75%, var(--red) 100%); }
.Card.negative .card-value-impact .card-second-value-value{
  left: 100%;
  padding-left: 5px;
  color: var(--red);
  font-weight: bold;
}




.Card.positive { background: var(--lightgold); }
.Card.positive .card-top .card-title { color: var(--darkgold); }
.Card.positive .card-category { color: var(--gold); }
.Card.positive .card-top .card-title .card-id{  background: var(--darkgold); color: #FFF;}
.Card.positive .card-desc {  color: var(--gold);}
.Card.positive .card-value .card-value-title {color: var(--gold);}
.Card.positive .card-value .card-value-bar { background: var(--gold); z-index: 2; }
.Card.positive .card-value .card-value-value { color: #FFF; z-index: 2;}

.Card.positive .card-value-impact .card-second-value-bar{
  transform: translateX(-15px);
  padding-left: 10px;
  background-image: linear-gradient(-45deg, var(--darkgold) 25%, var(--gold) 25%, var(--gold) 50%, var(--darkgold) 50%, var(--darkgold) 75%, var(--gold) 75%, var(--gold) 100%);
  z-index: 0;
}
.Card.positive .card-value-impact .card-second-value-value{
  left: 100%;
  padding-left: 5px;
  color: var(--darkgold);
  font-weight: bold;
}



.Wrapup-screen .Card .card-value-complexity .card-value-value { color: var(--grey5); }
.Wrapup-screen .Card .card-value-complexity .card-value-bar { background: transparent; }