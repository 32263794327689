* {box-sizing: border-box;}


:root {
  --bcg-green: #177b57;
  --bcggreen-transparent: rgba(5, 31, 20, 0.733);
  --lightgreen: #00be71;
  --lightgreen-transparent: #00be7166;
  --grey:#EAEAEA;
  --darkgrey: #B9B9B9;
  --red: #B75C8E;
  --lightred: #FEE3F0;
  --darkred: #721760;

  --gold: rgb(196, 167, 3);
  --lightgold: #fffadb;
  --darkgold: rgb(121, 103, 1);




  --grey1: #F2F2F2;
  --grey2: #EAEAEA;
  --grey3: #B9B9B9;
  --grey4: #868686;
  --grey5: #555555;

}

body {
  margin: 0;
  background: var(--grey1);
  font-family: 'Open Sans', sans-serif;



}

html, body, #root, .App {
  user-select: none;
  width: 100%;
  height: 100%;
}

.header {
  background: var(--grey);
  margin: 0;
  padding: 5px;
  width: 100%;
}



.debugMode {
  position: fixed;
  bottom: 0;
  left: 0;
  background: red;
  color: white;
}
