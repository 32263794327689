.shadowhide {
  height: 30px;
  width: 100%;
  /* background: red; */
  position: relative;
  /* z-index: 4; */
}
/* .help-button {
  position: fixed;

  bottom: 30px;
  right: 30px;
  background: var(--lightgreen);

  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 35px;
  font-weight: bold;
  color: #FFF;


  box-shadow: 0 0 15px var(--lightgreen-transparent);

  width: 70px;
  height: 70px;

  cursor: pointer;

  transition: all 0.3s;

} */

/*
.help-button:hover {
  background: var(--bcg-green);
  box-shadow: 0 0  20px 5px var(--lightgreen-transparent);
} */